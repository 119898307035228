import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPaintbrush,
    faCode, faSearchDollar,
    faAreaChart,
    faMobileScreen, faTools
} from '@fortawesome/free-solid-svg-icons'

function Services(){
    return (
        <section id="services">
            <div className="title">
                <h2>Services that we can help you with</h2>
                <p>
                    We provide a full package of services, which means that we will design and develop your product from A to Z, taking into account all your business requirements.
                </p>
            </div>
            <div className="services">
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faPaintbrush} />
                    </div>
                    <h3>Design</h3>
                    <p>
                        Our design team has vast experience in creating user interfaces with amazing user experience.
                    </p>
                </div>
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faCode} />
                    </div>
                    <h3>Development</h3>
                    <p>
                        Our highly qualified development team is ready to fight  new challenges
                    </p>
                </div>
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faSearchDollar} />
                    </div>
                    <h3>SEO</h3>
                    <p>
                        Our SEO team is ready to carefully optimize your sites for all major search engines.
                    </p>
                </div>
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faAreaChart} />
                    </div>
                    <h3>Marketing</h3>
                    <p>
                        Our marketing team is ready to raise and attract new customers for your products and services
                    </p>
                </div>
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faMobileScreen} />
                    </div>
                    <h3>App Development</h3>
                    <p>
                        Our development team can develop applications for Android, iOS, Windows, GNU\Linux and mac OS for your business
                    </p>
                </div>
                <div className="service">
                    <div className="icon">
                        <FontAwesomeIcon icon={faTools} />
                    </div>
                    <h3>Support</h3>
                    <p>
                        Our team is ready to support your business IT infrastructure, even if it is developed by others
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Services;
