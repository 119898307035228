import {useState} from "react";

function Nav (){
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className={open ? 'toggle {nav active': 'toggle {nav'}
            onClick={function (){
                console.info(open)
                setOpen(!open);
            }}
            />
            <div className={open ? 'navigation active' : 'navigation'}>
                <ul>
                    <li><a href="#home" onClick={function (){
                        setOpen(false);
                    }}>Home</a></li>
                    <li><a href="#services" onClick={function (){
                        setOpen(false);
                    }}>Services</a></li>
                    <li><a href="#work" onClick={function (){
                        setOpen(false);
                    }}>Work</a></li>
                    <li><a href="#contact" onClick={function (){
                        setOpen(false);
                    }}>Contact</a></li>
                </ul>
                <div className="social-bar">
                    <ul>
                        <li>
                            <a href="https://facebook.com">
                                <img
                                    src="https://github.com/bradtraversy/creative-agency-website/blob/master/images/facebook.png?raw=true"
                                    target="_blank" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com">
                                <img
                                    src="https://github.com/bradtraversy/creative-agency-website/blob/master/images/twitter.png?raw=true"
                                    target="_blank" alt=""/>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com">
                                <img
                                    src="https://github.com/bradtraversy/creative-agency-website/blob/master/images/instagram.png?raw=true"
                                    target="_blank" alt=""/>
                            </a>
                        </li>
                    </ul>
                    <a href="mailto:you@email.com" className="email-icon">
                        <img
                            src="https://github.com/bradtraversy/creative-agency-website/blob/master/images/email.png?raw=true"
                            alt=""/>
                    </a>
                </div>
            </div>
        </>
    )
}

export default Nav;
