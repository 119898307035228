import { LazyLoadImage } from 'react-lazy-load-image-component';

function Work(){
    return (
        <section id="work">
            <div className="title">
                <h1>Some of our finest work</h1>
                <p>
                    Some of the projects in our portfolio that have revolutionized the field and are loved by millions of users.
                </p>
            </div>
            <div className="portfolio">
                <div className="item" style={{background: '#3C444C'}}>
                    <LazyLoadImage
                        src="/images/MegaPush.png"
                        alt="MegaPush"/>
                    <div className="action">
                        <a href="https://megapu.sh" target="_blank">Go to website</a>
                    </div>
                </div>
                <div className="item" style={{background: '#000000'}}>
                    <LazyLoadImage
                        src="/images/hnhh_TV.png"
                        alt="Hot New Hip Hop"/>
                    <div className="action">
                        <a href="https://hotnewhiphop.com" target="_blank">Go to website</a>
                    </div>
                </div>
                <div className="item">
                    <LazyLoadImage
                        src="/images/PropertyVision.svg"
                        alt="Property Vision"/>
                    <div className="action">
                        <a href="https://propertyvision.ca" target="_blank">Go to website</a>
                    </div>
                </div>
                <div className="item" style={{background: '#000000'}}>
                    <LazyLoadImage
                        style={{
                            width: '500px',
                            marginLeft: '48px',
                        }}
                        src="/images/BoomerangMe.svg"
                        alt="Boomerang me"/>
                    <div className="action">
                        <a href="https://boomerangme.biz" target="_blank">Go to website</a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Work;
