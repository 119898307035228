import {LazyLoadImage} from "react-lazy-load-image-component";

function Home(){
    return (
        <section className="home" id="home">
            <LazyLoadImage
                className="home-img"
                src="/images/side.jpg"
                alt="Team"/>
            <div className="home-content">
                <h2>
                    We help <span className="highlight">you</span> to achieve <br/>
                    <span className="highlight">your goals</span>
                </h2>
                <p>
                    We can help you reimagine your business through a digital lens. Our software engineering heritage combined with our strategic business and innovation consulting, design thinking, and physical-digital capabilities provide real business value to our customers through human-centric innovation.
                </p>
                <a href="#services" className="btn">Get Started</a>
            </div>
        </section>
    )
}

export default Home;
