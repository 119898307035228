import './App.css';
import Nav from "./components/Nav";
import Home from "./sections/Home";
import Services from "./sections/Services";
import Work from "./sections/Work";
import Contacts from "./sections/Contacts";

function App() {
  return (
      <div>
        <header>
          <div className="logo">
            RED <span>M</span>
          </div>
            <Nav  />
        </header>
          <Home />
          <Services />
          <Work />
          <Contacts />
      </div>
  );
}

export default App;
