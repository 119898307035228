import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMapLocation, faMessage, faMobileScreen} from "@fortawesome/free-solid-svg-icons";

function Contacts(){
    return (
        <section id="contact">
            <div className="title">
                <h2>Contact us</h2>
                <p>
                    How can we help you? Please write your ideas, concerns and questions here, we will get back to you within 48 hours
                </p>
            </div>
            <div className="contact">
                <div className="contact-form">
                    <form>
                        <div className="row">
                            <div className="input50">
                                <input type="text" placeholder="First Name"/>
                            </div>
                            <div className="input50">
                                <input type="text" placeholder="Last Name"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input50">
                                <input type="text" placeholder="Email"/>
                            </div>
                            <div className="input50">
                                <input type="text" placeholder="Subject"/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input100">
                                <textarea placeholder="Message"></textarea>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input100">
                                <input type="submit" value="Send"/>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="contact-info">
                    <div className="info-box">
                        <FontAwesomeIcon className="contact-icon" icon={faMapLocation} />
                        <div className="details">
                            <h4>Address</h4>
                            <p>3 Grigor Lusavorich, Yerevan, Armenia</p>
                        </div>
                    </div>
                    <div className="info-box">
                        <FontAwesomeIcon className="contact-icon" icon={faMessage} />
                        <div className="details">
                            <h4>Email</h4>
                            <a href="mailto:contacts@red-m.tech">contacts@red-m.tech</a>
                        </div>
                    </div>
                    <div className="info-box">
                        <FontAwesomeIcon className="contact-icon" icon={faMobileScreen} />
                        <div className="details">
                            <h4>Call</h4>
                            <a href="tel:+37491666115">+374 91 666 115</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contacts;
